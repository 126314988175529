// ========================================

//     btn
//         ボタンのスタイル

//     INDEX
//         - class

// ========================================


// ========================================
//     class
// ========================================
.button {
    width: 90%;
    max-width: 500px;
    display: block;
    margin: 0 auto;
    border: none;
    text-align: center;
    font-weight: bold;
    padding: 20px 10px;
    color: $C_WHITE !important;
    background: $C_BLUE !important;
    font-size: 28px;

    @include mq-m() {
        font-size: 18px;
        padding: 14px 10px;
    }

    &:hover {
        opacity: 0.65;
    }

    &__none {
        color: $C_WHITE !important;
        background: #222 !important;
    }

    &__re {
        padding: 10px;
        font-size: 20px;
        color: $C_WHITE !important;
        background: #888 !important;

        @include mq-m() {
            padding: 10px;
            font-size: 15px;
        }
    }

    &__off {
        color: $C_WHITE !important;
        background: #d0d0d0 !important;

        &:hover {
            opacity: 1;
        }
    }
}