// ========================================
//
//      mixins
//          何度も使う重複する記述を定義
//    
//      INDEX
//          - float
//          - media query
//
// ========================================

// ========================================
//    float
// ========================================
// floatの解除
@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


// ========================================
//    media query
// ========================================

// ----------------------------------------
// ブラウザ幅に応じて反映するスタイルを変えるmixin
// これを使えばあちこちに同じクラス名を書かなくて済む
//
// ＜前提＞
//  $BP_XL: 1200px;
// $BP_L: 960px;
// $BP_M: 768px;
// $BP_S: 480px;
//
// ＜例＞
//  .box {
//      // 全幅で効くスタイル

//      @include mq-xl() {
//          // 1200px以下の時に効くスタイル
//      }
//     
//      @include mq-l() {
//          // 960px以下の時に効くスタイル
//      }
//
//      @include mq-m() {
//          // 750px以下の時に効くスタイル
//      }
//
//      @include mq-s() {
//          // 480px以下の時に効くスタイル
//      }
//  }
// ----------------------------------------

// media-query($BP_XL)を呼びやすくしたもの
// XLサイズ以下の場合にスタイルを効かせる
@mixin mq-xl() {
    @include media-query($BP_XL) {
        @content;
    }
}

// media-query($BP_L)を呼びやすくしたもの
// Lサイズ以下の場合にスタイルを効かせる
@mixin mq-l() {
    @include media-query($BP_L) {
        @content;
    }
}

// media-query($BP_M)を呼びやすくしたもの
// Mサイズ以下の場合にスタイルを効かせる
@mixin mq-m() {
    @include media-query($BP_M) {
        @content;
    }
}

// media-query($BP_S)を呼びやすくしたもの
// Sサイズ以下の場合にスタイルを効かせる
@mixin mq-s() {
    @include media-query($BP_S) {
        @content;
    }
}

// media-query($BP_XS)を呼びやすくしたもの
// XSサイズ以下の場合にスタイルを効かせる
@mixin mq-xs() {
    @include media-query($BP_XS) {
        @content;
    }
}

// "引数の幅以下"の場合のみ適応されるスタイル
@mixin media-query($BP) {
    @media screen and (max-width: $BP) {
        @content;
    }
}
