// ========================================

//     detail
//         detailページのみで使うスタイル

//     INDEX
//         - main
//         - post-entry

// ========================================


//========================================
//  main
// ========================================
.artist-detail {
    padding: 40px 20px;

    @include mq-l() {
        padding: 20px 20px 40px;
    }

    @include mq-m() {
        padding: 10px 10px 40px;
    }

    &__inner {
        width: 100%;
        max-width:1138px;
        margin: 0 auto;
        padding: 0 50px;

        @include mq-l() {
            padding: 0;
        }
    }

    &__img {
        width: 100%;
        max-width:1138px;
        margin: 0 auto 25px;

        @include mq-m() {
            margin: 0 auto 20px;
        }


        img {
            width: 100%;
            max-width:1190px;
        }
    }

    .detail-date {
        display: table;
        margin-bottom: 20px;

        &__title {
            display: table-cell;
            background: $C_MAIN;
            color: $C_WHITE;
            font-size: 1.5em;
            line-height: 1.2em;
            padding: 6px 12px;
            vertical-align: middle;

            @include mq-m() {
                padding: 6px 8px;
                font-size: 1.1em;
            }
        }
        &__start,
        &__end {
            display: table-cell;
            font-family: $ENG_FONT_FAMILY;
            font-size: 42px;
            font-weight: 700;
            line-height: 1em;
            vertical-align: middle;

            @include mq-m() {
                font-size: 30px;
            }

            span {
                display: block;
                font-size: 25px;
                line-height: 1em;

                @include mq-m() {
                    font-size: 16px;
                }
            }
        }
        &__start {
            padding: 0 5px 0 15px;

            @include mq-m() {
                padding: 0 5px 0 10px;
            }
        }
        &__end {
            padding: 0 15px 0 5px;

            @include mq-m() {
                padding: 0 10px 0 5px;
            }
        }
        &__hyphen {
            display: table-cell;
            font-family: $ENG_FONT_FAMILY;
            font-size: 30px;
            font-weight: 700;
            line-height: 1em;
            vertical-align: bottom;
            padding-bottom: 8px;

            @include mq-m() {
                padding-bottom: 2px;
            }
        }
    }

    .detail-title {
        font-size: 22px;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        font-weight: bold;
        margin-bottom: 40px;
        color: $C_BLUE;

        @include mq-m() {
            font-size: 17px;
            margin-bottom: 25px;
        }
    }

    .detail-text {
        font-size: 15px;
        letter-spacing: 0.05em;
        margin-bottom: 40px;

        @include mq-m() {
            font-size: 14px;
        }
    }

    .detail-cont {
        padding-bottom: 30px;
        
    }
}


//========================================
//  post-entry
// ========================================
.post-entry,
.post-link {
    font-size: 14px;
    margin-bottom: 30px;
    word-break: break-all;

    h1,
    h2 {
        font-size: 20px;
        line-height: 1.25em;
        letter-spacing: 0.05em;
        font-weight: bold;
        border-bottom: 3px double $C_MAIN;
        margin: 20px 0 10px;
        padding-bottom: 5px;
    }
    h3 {
        font-size: 18px;
        line-height: 1.25em;
        letter-spacing: 0.05em;
        font-weight: bold;
        border-bottom: 1px solid $C_MAIN;
        margin: 20px 0 10px;
        padding-bottom: 5px;
    }
    h4 {
        font-size: 16px;
        line-height: 1.25em;
        letter-spacing: 0.05em;
        font-weight: bold;
        border-bottom: 1px dashed $C_MAIN;
        margin: 20px 0 10px;
        padding-bottom: 5px;
    }
    h5 {
        font-size: 15px;
        line-height: 1.25em;
        letter-spacing: 0.05em;
        font-weight: bold;
        margin: 20px 0 10px;

        &::before {
            content: "■";
            display: inline-block;
            margin-right: 5px;
        }
    }
    h6 {
        font-size: 15px;
        line-height: 1.25em;
        letter-spacing: 0.05em;
        font-weight: bold;
        margin: 20px 0 10px;
    }

    img {
        display: inline-block;
        margin: 0 auto;
        width: 50%;
        max-width: 100%;
        height: auto !important;
        padding: 10px;

        &.al-c {
            display: block !important;
            width: auto !important;
            max-width: 100%;
        }

        @include mq-m() {
            display: block;
            width: auto !important;
        }
    }

    table {
        width: 100% !important;
        margin: 5px auto;
        text-align: auto !important;

        caption {
            border: solid 1px #4a5054 !important;
            background: #4a5054;
            color: $C_WHITE;
            padding: 5px 10px;
            text-align: left;
        }
        td {
            border: solid 1px #4a5054 !important;
            padding: 5px 10px;
            text-align: left;
        }
    }

    a {
        text-decoration: underline;
        color: $C_BLUE;
    }
}

.post-link {
    text-align: center;
    word-break: break-all;
    
    img,
    a {
        display: inline-block;
        height: auto !important;
    }
}


.detail-attention {
    background: #d4dbe1;
    padding: 30px 20px;
    margin-bottom: 30px;
    word-break: break-all;

    &__title {
        text-align: center;
        color: $C_RED;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: bold;
        padding: 0 10px 5px;
        margin: 0 auto 20px;
        border-bottom: 3px solid $C_RED;
        width: 240px;
    }
    
    img {
        display: block;
        margin: 5px auto;
        max-width: 100%;
        height: auto !important;
    }

    a {
        text-decoration: underline;
        color: $C_BLUE;
    }
}