// ========================================

//     index
//         indexページのみで使うスタイル

//     INDEX
//         - main

// ========================================


//========================================
//  h
// ========================================
h1,
h2,
h3,
h4,
h5 {
    padding: 0;
    margin: 0;
}

.h3__title {
    font-size: 20px;
    line-height: 1.25em;
    letter-spacing: 0.05em;
    font-weight: bold;
    border-bottom: 3px double $C_MAIN;
    margin: 20px 0 30px;
    padding-bottom: 5px;

    @include mq-m() {
        font-size: 16px;
        line-height: 1.25em;
    }
}
.h4__title {
    font-size: 16px;
    line-height: 1.25em;
    letter-spacing: 0.05em;
    font-weight: bold;
    border-bottom: 1px dashed $C_MAIN;
    margin: 20px 0 10px;
    padding-bottom: 5px;

    @include mq-m() {
        font-size: 14px;
        line-height: 1.25em;
    }
}


//========================================
//  main
// ========================================
.artist-cam {
    padding: 60px 0;

    @include mq-m() {
        padding: 40px 0 10px;
    }

    &__inner {
        width: 100%;
        max-width:1190px;
        margin: 0 auto;
    }
    
    &__title {
        text-align: center;
        color: $C_BLUE;
        font-weight: 700;
        font-size: 40px;
        line-height: 1em;
        margin-bottom: 50px;

        @include mq-m() {
            font-size: 26px;
            margin-bottom: 40px;
        }

        span {
            display: block;
            font-family: $ENG_FONT_FAMILY;
            color: $C_GRAY;
            font-size: 20px;
            text-transform: uppercase;

            @include mq-m() {
                font-size: 16px;
            }
        }
    }

    &__list {
        width: 100%;
        @include clearfix();

        li {
            width: 50%;
            float: left;
            padding: 0 20px;
            margin-bottom: 40px;

            @include mq-m() {
                width: 100%;
                padding: 0 10px;
            }

            a {
                display: block;
            }
        }

        &--box {
            display: block;
        }
    }

    .list-img {
        margin-bottom: 10px;
        img {
            width: 100%;
        }
    }
    .list-date {
        display: table;
        margin-bottom: 6px;

        &__title {
            display: table-cell;
            background: $C_MAIN;
            color: $C_WHITE;
            font-size: 1.1em;
            line-height: 1.2em;
            padding: 8px 10px;
            vertical-align: middle;

            @include mq-m() {
                padding: 6px 8px;
            }
        }
        &__start,
        &__end {
            display: table-cell;
            font-family: $ENG_FONT_FAMILY;
            font-size: 32px;
            font-weight: 700;
            line-height: 1em;
            vertical-align: middle;

            @include mq-m() {
                font-size: 30px;
            }

            span {
                display: block;
                font-size: 16px;
                line-height: 1em;
            }
        }
        &__start {
            padding: 0 5px 0 15px;

            @include mq-m() {
                padding: 0 5px 0 10px;
            }
        }
        &__end {
            padding: 0 15px 0 5px;

            @include mq-m() {
                padding: 0 10px 0 5px;
            }
        }
        &__hyphen {
            display: table-cell;
            font-family: $ENG_FONT_FAMILY;
            font-size: 30px;
            font-weight: 700;
            line-height: 1em;
            vertical-align: bottom;
            padding-bottom: 8px;

            @include mq-m() {
                padding-bottom: 2px;
            }
        }
    }


    &__end {
        background: #d4dbe1;

        .list-date {
            color: $C_GRAY;

            &__title {
                background: $C_GRAY;
            }
        }

        p {
            color: $C_GRAY;
        }
    }

    &__nothing {
        display: table;
        width: 100%;
        height: calc(100vh - 240px);

        @include mq-m() {
            height: calc(100vh - 350px);
        }

        .artist-cam__inner {
            display: table-cell;
            vertical-align: middle;
        }

        .artist-cam__title {
            color: #ddd;
            font-size: 25px;

            @include mq-m() {
                font-size: 20px;
                line-height: 1.8em;
            }
        }
    }
}


//========================================
//  maintenance
// ========================================
.maintenance {
    background: #eef0f1;
    width: 100%;
    padding: 10px 30px;
    margin-bottom: 20px;
    text-align: center;

    @include mq-m() {
        padding: 10px;
        font-size: 0.9em;
        line-height: 1.5em;
        text-align: left;
    }

    &__inner {
        // width: 100%;
        // max-width: 1190px;
        // margin: 0 auto;
    }
}