/* ========================================
    
    base
        タグに直接効かせるスタイル

    INDEX
        - common
        - anti-aliasing
        - form

======================================== */

/* ========================================
    common
======================================== */
* {
    box-sizing: border-box;
}


html {
    height: 100%;
}

body {
    background: $C_WHITE;
    font-family: $MAIN_FONT_FAMILY;
    font-size: 14px;
    line-height: 1.75em;
    letter-spacing: 0.05em;
    font-feature-settings: "pkna";
    color: $C_MAIN;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    width: 100%;
    height:100%;
}


img {
    vertical-align: bottom;
    max-width: 100%;
    height: auto;
}

a {
    color: $C_LINK;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
    }
}

ul,
li {
    list-style: none;
    margin: 0 auto;
    padding: 0;
}



#wrapper {
    margin:0 auto;
    position: relative;
    min-height: 100%;
    width: 100%;
    height: auto !important;
    height: 100%;
    background-color: $C_WHITE;
}

.wrapper__inner {
    padding-bottom: 155px;

    @include mq-m() {
        padding-bottom: 260px;
    }
}



/* ========================================
    anti-aliasing
======================================== */
// 文字のアンチエイリアス
// http://creator.dwango.co.jp/14128.html
// http://qiita.com/htomine/items/3086f30ca09adea2f8e0
// フォントレンダリング設定: 1x解像度ではsubpixel、2x以上の解像度ではgrayscale

body,
html {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    body,
    html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}


/* ========================================
    form
======================================== */
// スマホ、タブレットのformの見た目を整える
input,
textarea,
select {
    @include mq-l() {
        font-size: 14px;
    }
}

// iOSのデフォルトデザインが消えます
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="text"],
input[type="email"],
textarea,
select {
    appearance: none;
}

input[type="text"],
input[type="email"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="email"],
textarea {
    border-radius: 0;
    border: none;
    box-sizing: border-box;
    background-color: transparent;

    &:-webkit-search-decoration {
        display: none;
    }

    &:focus {
        outline-offset: -2px;
    }
}