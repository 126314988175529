// ========================================

//     list
//         リストのスタイル

//     INDEX
//         - text list
//         - thumb list
//         - circle list

// ========================================

// ========================================
//  list
// ========================================
.list {
    &__number {
        li {
            list-style-type: decimal;
            margin-left: 15px;
        }
    }
    &__disc {
        li {
            list-style-type: disc;
            margin-left: 15px;
        }
    }
}
