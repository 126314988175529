// ========================================

//     footer
//         フッターのパーツ

//     INDEX
//         - common

// ========================================

// ========================================
//     common
// ========================================
.footer {
    width: 100%;
    background: $C_MAIN;
    color: $C_WHITE;
    position: absolute;
    bottom: 0;

    &__inner {
        padding: 40px 20px;

        @include mq-m() {
            padding: 30px 10px;
        }
    }

    &__nav {
        text-align: center;
        padding-bottom: 20px;

        li {
            display: inline-block;
            padding: 5px 10px;
        }
        a {
            width: 100%;
            padding: 0;
            color: $C_WHITE;
            border-bottom: 1px solid #888;

            @include mq-m() {
                font-size: 13px;
            }
        }
    }

    .copy {
        font-size: 0.9em;
        color: $C_GRAY;
        text-align: center;
    }
}

