// ========================================

//     index
//         indexページのみで使うスタイル

//     INDEX
//         - faq
//         - contact

// ========================================

//========================================
//  faq
// ========================================
.faq {
    padding: 60px 0;

    @include mq-m() {
        padding: 40px 0;
    }

    &__inner {
        width: 100%;
        max-width:1190px;
        margin: 0 auto;
    }
    
    &__title {
        text-align: center;
        color: $C_BLUE;
        font-weight: 700;
        font-size: 40px;
        line-height: 1em;
        margin-bottom: 25px;

        @include mq-m() {
            font-size: 26px;
            margin-bottom: 15px;
        }

        span {
            display: block;
            font-family: $ENG_FONT_FAMILY;
            color: $C_GRAY;
            font-size: 20px;
            text-transform: uppercase;

            @include mq-m() {
                font-size: 16px;
            }
        }
    }

    &__list {
        padding: 15px 10px;
        border-bottom: 1px dashed $C_MAIN;

        dt {
            position: relative;
            padding: 15px 0 20px 55px;
            color: $C_GRAY;

            @include mq-m() {
                padding: 2px 0 18px 38px;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "Q";
                margin-right: 5px;
                color: $C_BLUE;
                font-family: $ENG_FONT_FAMILY;
                font-size: 50px;
                line-height: 1em;
                padding-bottom: 20px;

                @include mq-m() {
                    font-size: 30px;
                }
            }
        }
        dd {
            position: relative;
            padding: 5px 0 20px 55px;

            @include mq-m() {
                padding: 2px 0 18px 38px;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "A";
                margin-right: 5px;
                color: $C_RED;
                font-family: $ENG_FONT_FAMILY;
                font-size: 50px;
                line-height: 1em;
                padding-bottom: 20px;

                @include mq-m() {
                    font-size: 30px;
                }
            }
        }
    }
}



//========================================
//  contact
// ========================================
.contact {
    padding: 60px 0;

    @include mq-m() {
        padding: 40px 10px;
    }

    &__inner {
        width: 100%;
        max-width:1190px;
        margin: 0 auto;
    }
    
    &__title {
        text-align: center;
        color: $C_BLUE;
        font-weight: 700;
        font-size: 40px;
        line-height: 1em;
        margin-bottom: 40px;

        @include mq-m() {
            font-size: 26px;
            margin-bottom: 20px;
        }

        span {
            display: block;
            font-family: $ENG_FONT_FAMILY;
            color: $C_GRAY;
            font-size: 20px;
            text-transform: uppercase;

            @include mq-m() {
                font-size: 16px;
            }
        }
    }
}

.contact-attention {
    background: #d4dbe1;
    padding: 10px 20px;
    margin-bottom: 40px;
    word-break: break-all;
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
}