// ========================================
//
//      variables
//          サイト全体で使う変数定義
//
//      INDEX
//          - break point
//          - color
//          - path
//          - easing
//
// ========================================

// ========================================
//    break point
// ========================================
$BP_XL: 1200px; // PC
$BP_L: 960px;   // PC
$BP_M: 768px;   // TB
$BP_S: 480px;   // SP
$BP_XS: 320px;  // SP


// ========================================
//    color
// ========================================
$C_WHITE: #fff;
$C_GRAY: #636363;
$C_BLACK: #000;

$C_RED: #de0000;
$C_BLUE: #0061aa;

$C_MAIN: #000;
$C_SUB: #636363;
$C_LINK: #111;


// ========================================
//    path
// ========================================
$IMG: '/images/';


// ========================================
//    font
// ========================================
@font-face {
    font-family: "LogoFont";
    src: url("/font/HibiSona.eot?") format('eot'),
        url("/font/HibiSona.woff2") format('woff2'),
        url("/font/HibiSona.woff") format('woff');
  }

$MAIN_FONT_FAMILY: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "游ゴシック", YuGothic, Meiryo, メイリオ, sans-serif;
$ENG_FONT_FAMILY: 'Montserrat', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "游ゴシック", YuGothic, Meiryo, メイリオ, sans-serif;


// ========================================
//    easing
// ========================================
$EASE_OUT_CUBIC: cubic-bezier(0.215, 0.61, 0.355, 1); // 開始直後が早い
$EASE_IN_CUBIC: cubic-bezier(0.55, 0.055, 0.675, 0.19); // 終了直前が早い
$EASE_OUT_BACK: cubic-bezier(0.175, 0.885, 0.32, 1.275); // 開始直後が早い、一回行き過ぎて帰ってくる
$EASE_IN_BACK: cubic-bezier(0.6, -0.28, 0.735, 0.045); // 終了直前が早い、一回行き過ぎて帰ってくる
