// =======================================

//     header
//         ヘッダーのパーツ

//     INDEX
//         - common

// ========================================

// ========================================
//     common
// ========================================
.header {
    width: 100%;
    // background: $C_MAIN;
    color: $C_MAIN;

    &__inner {
        padding: 10px 30px;
        text-align: center;

        @include mq-m() {
            padding: 10px;
        }
    }

    .logo {
        width: 100%;
        max-width: 130px;

        @include mq-m() {
            max-width: 100px;
        }

        img {
            width: 100%;
        }
    }
}