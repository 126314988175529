// ========================================

//     nav
//         ナビのパーツ

//     INDEX
//         - common

// ========================================

// ========================================
//     common
// ========================================
.nav {
    background: $C_WHITE;
    padding: 15px 0;

    &__inner {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        text-align: center;
        @include clearfix();
    }

    li {
        width: 25%;
        float: left;
        text-align: center;
        border-right: 1px solid $C_LINK;

        &:first-child {
            border-left: 1px solid $C_LINK;
        }
    }

    a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 8px 0;
        font-weight: bold;
        font-size: 20px;
        line-height: 1em;
    }
}
