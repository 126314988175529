// ========================================

//     form
//         formページのみで使うスタイル

//     INDEX
//         - main
//         - post-entry

// ========================================


//========================================
//  main
// ========================================
.form {
    &__inner {
        width: 100%;
        max-width:1138px;
        margin: 0 auto 50px;
        padding: 0 50px;

        @include mq-m() {
            padding: 0;
        }
    }

    &__table {
        margin: 0 auto 30px;
        width: 100%;

        th {
            border: solid 1px #4a5054;
            padding: 20px 15px 15px;
            width: 25%;
            vertical-align: top;

            @include mq-m() {
                display: block;
                width: 100%;
                padding: 5px 10px;
                background: #d4dbe1;
                border-bottom: none;
            }
        }
        td {
            border: solid 1px #4a5054;
            padding: 15px;

            @include mq-m() {
                display: block;
                width: 100%;
                padding: 15px 10px;
            }
        }
    }

    &__title {
        font-size: 20px;
        line-height: 1.25em;
        letter-spacing: 0.05em;
        font-weight: bold;
        border-bottom: 3px double $C_MAIN;
        margin: 20px 0 10px;
        padding-bottom: 5px;
    }
}


.form20,
.form30,
.form50,
.form80,
.form100,
.box {
    border: 1px solid #b9bdc1 !important;
    border-radius: 6px !important;
    padding: 6px;
    font-size: 14px;
    background: #f4f4f4 !important;
}
.form20 {
    width: 20%;
}
.form30 {
    width: 30%;
}
.form50 {
    width: 50%;
}
.form80 {
    width: 80%;
}
.form100 {
    width: 100%;
}
.box {
    width: 100%;
    height: 100px;
}

.selectWrap-1,
.selectWrap-2,
.selectWrap-3 {
    select {
        padding: 5px 30px 5px 5px;
        border: 1px solid #b9bdc1;
        border-radius: 6px;
        background: url(/images/form_arw.png) right 50% no-repeat #f4f4f4;
        background-size: 20px auto;
        
        &:-ms-expand {
            display: none;
        }
    }
    span {
        display: inline-block;
        margin: 0 6px;
    }
}
.selectWrap-1 {
    select {
        width: 100%;
    }
}
.selectWrap-2 {
    select {
        width: 50%;
    }
}
.selectWrap-3 {
    select {
        width: 25%;
    }
}




input[type="checkbox"],
input[type="radio"]{
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
    position: absolute;
    left: -1000em;
}



label input[type="checkbox"] + span {
    display: inline-block;
    position: relative;
    padding: 0 0 0 28px;
    margin-right: 15px;
    text-align: left;
}

label input[type="checkbox"] + span:before,
label input[type="checkbox"] + span:after {
    position: absolute;
    top: 3px;
    left: 0;
    display: inline-block;
    content: '';
    box-sizing: border-box;
}

label input[type="checkbox"] + span::before {
    z-index: 0;
    background-color: transparent;
    width: 20px;
    height: 20px;
    border: 1px #b9bdc1 solid;
    background: #f4f4f4;
    border-radius: 5px;
}
label input[type="checkbox"] + span::after {
    z-index: 1;
    margin: 5px 8px;
    width: 6px;
    height: 9px;
}

label input[type="checkbox"]:checked + span:before {
    border: 1px #222 solid;
    background-color: #222;
}
label input[type="checkbox"]:checked + span:after {
    border: 2px solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


label input[type="radio"] + span {
    display: inline-block;
    position: relative;
    padding: 0 0 0 28px;
    margin: 0 10px 0 6px;
    text-align: left;
}

label input[type="radio"] + span:before {
    position: absolute;
    display: inline-block;
    content: '';
    box-sizing: border-box;
    border-radius: 20px;
}

label input[type="radio"] + span:before {
    z-index: 0;
    top: 3px;
    left: 0;
    background-color: transparent;
    width: 20px;
    height: 20px;
    border: 1px #b9bdc1 solid;
}

label input[type="radio"]:checked + span:before {
    border: 6px #222  solid;
}




.tel_txt {
    display: inline-block;
    width: 45px;

    @include mq-m() {
        width: 35px;
    }
}
.attention {
    color: $C_RED;
}
.end_txt {
    font-weight: bold;
    font-size: 1.2em;
    padding: 50px 0;
    color: $C_RED;
    text-align: center;
}


.agree-box {
    font-size: 14.5px;
    text-align: center;
    margin-bottom: 20px;

    label input[type="checkbox"] + span {
        margin-right: 0;
    }
}


.submit--box {
    @include clearfix();
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .fl {
        width: 50%;

        @include mq-m() {
            float: none;
            width: 100%;
        }
    }
    .fr {
        width: 50%;

        @include mq-m() {
            float: none;
            width: 100%;
            margin-bottom: 10px;
        }
    }
}